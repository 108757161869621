import axiosInstance from '../util/axiosInstance'

export const getMachineFeatures = async (idMachine) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/machines/` + idMachine);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};

export const postMachine = async (machine) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/machines`, machine);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};
export const saveFeature = async (feature) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/machines/features`, feature);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};
export const getMachinesByClientIds = async (clients) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/machines/by-client-ids`, clients);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};



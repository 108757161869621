import React, { useEffect, useRef, useState } from 'react';
import { getReminders, getServicesList, saveService } from '../services/serviceServices';
import { Link } from 'react-router-dom';
import moment from 'moment';

const DashboardAdminPage = () => {

  const [services1, setServices1] = useState([]);
  const [services2, setServices2] = useState([]);
  const [services3, setServices3] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupEnd, setShowPopupEnd] = useState(false);
  const [showPopupFile, setShowPopupFile] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const mechanicNameInputRef = useRef(null);
  const fetchData = async () => {
    try {
      let services = await getServicesList(null, [1, 2, 3]);

      // Split services into three arrays based on status
      const services1 = services.filter(service => service.status === 1);
      const services2 = services.filter(service => service.status === 2);
      const services3 = services.filter(service => service.status === 3);

      // Update state with filtered arrays
      setServices1(services1);
      setServices2(services2);
      setServices3(services3);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {


    fetchData();
  }, []);

  const onStartClick = (service) => {
    setSelectedService(service);
    setShowPopup(true);
  };


  const handleSaveStart = async () => {
    setSelectedService({ ...selectedService, "mechanicName": "" });
    try {
      let servtoBd = { idService: selectedService.idService, mechanicName: selectedService.mechanicName, status: 2 };
      await saveService(servtoBd)
        .then((result) => {
          fetchData();
          setShowPopup(false);
        })
        .catch((error) => {
          console.log("Error creating user:", error);
        });
    } catch (error) {

    }
  };


  const saveFinishClick = async (service) => {
    setSelectedService(service);
    setShowPopupFile(true)

  }
  const saveFinishSave = async () => {

    try {
      let servtoBd = { idService: selectedService.idService, file: selectedService.file, status: 3 };
      await saveService(servtoBd)
        .then((result) => {
          fetchData();
          setShowPopupFile(false);
        })
        .catch((error) => {
          console.log("Error creating user:", error);
        });
    } catch (error) {

    }
  }

  const onDoneClick = async (service) => {
    setSelectedService(service);
    setShowPopupEnd(true);


  };

  const archiveService = async () => {

    try {
      let servtoBd = { idService: selectedService.idService, billNumber: selectedService.billNumber, status: 4 };
      await saveService(servtoBd)
        .then((result) => {
          fetchData();
          setShowPopupEnd(false);
        })
        .catch((error) => {
          console.log("Error creating user:", error);
        });
    } catch (error) {

    }
  }

  const onClose = () => {
    // Close the popup
    setShowPopup(false);
    setShowPopupEnd(false);
    setShowPopupFile(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let fullVal = value;
    if (name === 'file') {
      const file = event.target.files[0];
      // Update the selectedService with the file
      setSelectedService({ ...selectedService, [name]: file });
    } else {
      fullVal = event.target.value;
      setSelectedService({ ...selectedService, [name]: fullVal });
    }
  };

  useEffect(() => {
    // Focus on the input when the popup is shown
    if (showPopup && mechanicNameInputRef.current) {
      mechanicNameInputRef.current.focus();
    }
  }, [showPopup]);
  return (
    <div>
      <div className='row'>
        <div className='col col-lg-4'>
          <h5>Ordenes</h5>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Codigo</th>
                  <th>Cliente</th>
                  <th>Maquina</th>
                  <th>Fecha</th>
                  <th>Iniciar</th>
                </tr>
              </thead>
              <tbody>
                {services1.map(service => (
                  <tr key={service.idService}>
                    <td>
                      <a href={'services/' + service.idService} target='_blank' >{service.idService}</a>
                    </td>
                    <td>{service.clientName}</td>
                    <td>{service.machineName}</td>
                    <td> {moment(service.createDate).format("DD/MM/yyyy HH:mm")} </td>
                    <td><button onClick={() => onStartClick(service)} className='btn btn-success'><i className="bi bi-play"></i></button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='col col-lg-4'>
          <h5>En servicio:</h5>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Codigo</th>
                  <th>Cliente</th>
                  <th>Maquina</th>
                  <th>Mecanico</th>
                  <th>Fecha</th>
                  <th>Iniciar</th>
                </tr>
              </thead>
              <tbody>
                {services2.map(service => (
                  <tr key={service.idService}>
                    <td>
                      <a href={'services/' + service.idService} target='_blank' >{service.idService}</a>
                    </td>
                    <td>{service.clientName}</td>
                    <td>{service.machineName}</td>
                    <td>{service.mechanicName}</td>
                    <td> {moment(service.createDate).format("DD/MM/yyyy HH:mm")} </td>
                    <td><button className='btn btn-primary' onClick={() => saveFinishClick(service)}><i className="bi bi-check-circle"></i></button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


        </div>
        <div className='col col-lg-4'>
          <h5>Terminados:</h5>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Codigo</th>
                  <th>Cliente</th>
                  <th>Maquina</th>
                  <th>Mecanico</th>
                  <th>Fecha</th>
                  <th>Archivar</th>
                </tr>
              </thead>
              <tbody>
                {services3.map(service => (
                  <tr key={service.idService}>
                    <td>
                      <a href={'services/' + service.idService} target='_blank' >{service.idService}</a>
                    </td>
                    <td>{service.clientName}</td>
                    <td>{service.mechanicName}</td>
                    <td>{service.machineName}</td>
                    <td> {moment(service.createDate).format("DD/MM/yyyy HH:mm")} </td>
                    <td><button className='btn btn-information' onClick={() => onDoneClick(service)}><i className="bi bi-archive"></i></button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


        {/* Popup */}
        {showPopup && (
          <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Ingrese el nombre del mecanico:</h5>
                </div>
                <div className="modal-body">
                  <div className='form'>
                    <div className='form-group'>
                      <label>Nombre:</label>
                      <input type="text" ref={mechanicNameInputRef} className='form-control' name='mechanicName' value={selectedService.mechanicName || ""} onChange={handleInputChange} />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleSaveStart}>Guardar</button>
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Popup */}
        {showPopupFile && (
          <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Seleccione el documento:</h5>
                </div>
                <div className="modal-body">
                  <div className='form'>
                    <div className='form-group'>
                      <label>Archivo:</label>
                      <input type="file" className='form-control' name='file' onChange={handleInputChange} />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={saveFinishSave}>Guardar</button>
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Popup */}
        {showPopupEnd && (
          <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Ingrese el numero de factura:</h5>
                </div>
                <div className="modal-body">
                  <div className='form'>
                    <div className='form-group'>
                      <label>Numero:</label>
                      <input type="text" className='form-control' name='billNumber' value={selectedService.billNumber || ""} onChange={handleInputChange} />
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={archiveService}>Guardar</button>
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardAdminPage;

import React, { useState } from 'react';
import { saveFeature } from '../../services/machinesServices';

const FeatureEditModal = ({ onClose, selectedFeature, fetchFeatures }) => {
    const [feature, setFeature] = useState(selectedFeature);


    const handleSave = async () => {
        try {
            await saveFeature(feature)
                .then((result) => {
                    fetchFeatures();
                    onClose();
                })
                .catch((error) => {
                    console.log("Error creating user:", error);
                });
        } catch (error) {

        }

    };


    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setFeature({ ...feature, [name]: value });
    };

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Editar Caracteristica</h5>
                        {/* <button type="button" className="close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Caracteristica:</label>
                            <input type="text" className="form-control" name='name' value={feature.name || ''} onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <label>Valor:</label>
                            <input type="text" className="form-control" name='value' value={feature.value || ''} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={handleSave}>Guardar</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureEditModal;

import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getUserInformation } from '../../services/userService';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../util/authContext';
import { authStore } from '../../store/auth.store';
import { shallow } from 'zustand/shallow';

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const { login, logout } = useContext(AuthContext);
  const { isAuthenticated, role } = authStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    role: state.role
  }), shallow);



  const handleLogout = async () => {
    localStorage.removeItem('token');
    logout();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      let decode = jwt_decode(token);
      const getUserRole = async () => { // add async keyword here
        try {
          const role = await getUserInformation(decode.username);
          login(role);
        } catch (error) {
          console.log('Error retrieving user role:', error);
        }
      };
      getUserRole();
    }
    else {
      logout();
      navigate('/');

    }


  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">Ordenes de Servicio</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              {/* <li className="nav-item">
                <a className="nav-link" href="/about">About</a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/contact">Contact</a>
              </li> */}

              {isAuthenticated ? (
                <>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/Dashboard" >Portal</Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/services/add" >Crear Servicio</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/users" >Usuarios</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/clients" >Clientes</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/services" >Servicios</Link>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">{role.email}</Link>
                    <ul className="dropdown-menu dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                      <Link className="dropdown-item" to="/profile">Profile</Link>
                      <a className="dropdown-item" href='#' onClick={handleLogout}>Logout</a>
                    </ul>
                  </li> */}
                </>
              ) : (
                null
              )}
            </ul>
          </div>

        </div>
      </nav>
      <div className="container">
        {children}
      </div>
      <footer className="mt-4">
        <div className="container">
          <p>&copy; {new Date().getFullYear()} Ciparcol. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;

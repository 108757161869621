import React, { useContext, useEffect, useState } from 'react';
import { getUsersList, saveUser } from '../../services/userService';
import moment from 'moment';

const UsersAdminPage = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setUser] = useState({});
    const [isChecked, setIsChecked] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await getUsersList();
                setUsers(users);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (name === "active")
            value = event.target.checked;

        setUser({ ...selectedUser, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        // Update the isChecked state with the new checkbox value
        setIsChecked(event.target.checked);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        var user = selectedUser;
        user.active = isChecked;
        await saveUser(user)
            .then((result) => {
                // Reload users list
                getUsersList().then((users) => {
                    setUsers(users);
                });

                // Clear the form after saving the user
                selectUser({ active: false });
                setIsChecked(false);
            })
            .catch((error) => {
                console.log("Error creating user:", error);
            });
    };

    const selectUser = (user) => {
        setUser(user);
        setIsChecked(user.active);
    };

    return (
        <form>
            <div >
                <h5>Lista Usuarios</h5>
                <div className='row'>
                    <div className='col-9'>
                        <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Usuario</th>
                                    <th>Clave</th>
                                    <th>Activo</th>
                                    <th>Fecha inicio</th>
                                    <th>Ultima actualizacion</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.username}</td>
                                        <td>{user.password}</td>
                                        <td>{user.active}</td>
                                        <td>{moment(user.creationDate).format("DD/MM/yyyy HH:mm")}</td>
                                        <td>{moment(user.updateDate).format("DD/MM/yyyy HH:mm")}</td>
                                        <td><a className='btn btn-sm btn-primary' onClick={(e) => selectUser(user)}> <i className="bi bi-arrow-bar-right"></i></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='col-3'>
                        <div className='form-group '>
                            <label className='form-label'>Nombre:</label>
                            <input className='form-control' type='text' name='name' value={selectedUser.name || ''} onChange={handleInputChange}></input>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Usuario:</label>
                            <input className='form-control' type='text' name='username' value={selectedUser.username || ''} onChange={handleInputChange}></input>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Activo?:</label>
                            <input className=" form-control-sm" type='checkbox' name='active' checked={isChecked} onChange={handleCheckboxChange}></input>
                        </div>
                        <button className="btn btn-default" onClick={() => { selectUser({ active: false }) }}>Limpiar</button>
                        <button className="btn btn-primary" onClick={handleSave}>Guardar</button>
                    </div>
                </div>

            </div>
        </form>
    );
};

export default UsersAdminPage;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getServicesById, saveService, saveWoleService } from '../../services/serviceServices';
import { getClientById, getClients } from '../../services/clientsServices';
import { authStore } from '../../store/auth.store';
import { shallow } from 'zustand/shallow';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'bootstrap';


const ServicesAddPage = () => {
    const { idService } = useParams();
    const [selectedService, setSelectedService] = useState({});
    const statusList = { 1: "Orden", 2: "En proceso", 3: "Terminado", 4: "Archivado" };
    const [clients, setClients] = useState([]);
    const [clientMachines, setClientMachines] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedMachine, setSelectedMachine] = useState({});
    const [services, setServices] = useState([]);
    const navigate = useNavigate();
    const { isAuthenticated, role } = authStore((state) => ({
        isAuthenticated: state.isAuthenticated,
        role: state.role
    }), shallow);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const clients = await getClients();
                setClients(clients);

                if (idService) {
                    const service = await getServicesById(idService);
                    setSelectedService(service);
                    const client = clients.find((c) => c.idClient === parseInt(service.idClient));
                    setSelectedClient(client);

                    const clientDetail = await getClientById(service.idClient);
                    setClientMachines(clientDetail.machines);
                    const machine = clientDetail.machines.find((m) => m.idMachine === parseInt(service.idMachine));
                    setSelectedMachine(machine);

                    setServices(service.detail);
                }
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchData();


    }, []);



    const handleClientChange = async (event) => {
        const clientId = event.target.value;
        const client = clients.find((c) => c.idClient === parseInt(clientId));
        setSelectedClient(client);

        if (clientId) {
            try {
                const clientDetail = await getClientById(clientId);
                setClientMachines(clientDetail.machines);

                // Do something with the client detail if needed
            } catch (error) {
                console.error('Error fetching client detail:', error);
            }
        }
    };

    const handleMachineChange = (event) => {
        const machineId = event.target.value;
        const machine = clientMachines.find((m) => m.idMachine === parseInt(machineId));
        setSelectedMachine(machine);
    };

    const handleServiceChange = (index, event) => {
        const { name, value } = event.target;
        let fullVal = value;
        if (name === 'file') {
            const file = event.target.files[0];
            // let updatedLstBox = [...shipping.boxes]; // Create a new array
            // updatedLstBox[id] = { ...updatedLstBox[id], file: file };
            fullVal = file;
            // setShipping({ ...shipping, boxes: updatedLstBox });
        }
        if (name === 'reminder') {
            fullVal = event.target.checked;

        }

        const newServices = [...services];
        newServices[index][name] = fullVal;
        setServices(newServices);

    };

    const handleAddService = () => {
        setServices([...services, { description: '', reminder: false }]);
    };

    const handleRemoveService = (index) => {
        const newServices = [...services];
        newServices.splice(index, 1);
        setServices(newServices);
    };

    const handleSave = async () => {
        // Format data and save sale
        if (!idService) {

            const saleData = {
                idMachine: selectedMachine.idMachine,
                idUser: role.idUser,
                detail: services
            };


            try {
                await saveWoleService(saleData).then((res) => {
                    // Clear form after saving sale
                    setSelectedClient({});
                    setSelectedMachine({});
                    setServices([]);
                }).catch((err) => {
                    console.log(err);
                });

            } catch (error) {
                console.error('Error saving sale:', error);
            }
        }
        else {


            try {
                let servToSave = { idService: idService, status: selectedService.status };
                await saveService(selectedService).then((servToSave) => {
                    navigate('/dashboard');

                }).catch((err) => {
                    console.log(err);
                });

            } catch (error) {
                console.error('Error saving sale:', error);
            }
        }

    };
    const handleStatusChange = (event) => {
        const status = event.target.value;
        setSelectedService((prevService) => ({ ...prevService, status }));
    };
    return (
        <form>
            <div>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5>Seleccionar Cliente</h5>
                        <div className="form-group">
                            <select className="form-control" value={selectedClient.idClient || ''} onChange={handleClientChange}>
                                <option value="">Seleccionar Cliente</option>
                                {clients.map((client) => (
                                    <option key={client.idClient} value={client.idClient}>{client.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        {selectedClient.idClient && (
                            <>
                                <h5>Seleccionar Máquina del Cliente</h5>
                                <div className="form-group">
                                    <select className="form-control" value={selectedMachine.idMachine || ''} onChange={handleMachineChange}>
                                        <option value="">Seleccionar Máquina</option>
                                        {clientMachines.map((machine) => (
                                            <option key={machine.idMachine} value={machine.idMachine}>{machine.name} - {machine.brand} - {machine.model} - {machine.ref}</option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    {selectedMachine.idMachine && (
                        <div className='col-md-2'>
                            <label className='form-control'><strong>Equipo:</strong> {selectedMachine.name}</label>
                            <label className='form-control'><strong>Marca:</strong> {selectedMachine.brand}</label>
                        </div>

                    )}
                    {selectedMachine.idMachine && (
                        <div className='col-md-2'>
                            <label className='form-control'><strong>Modelo:</strong> {selectedMachine.model}</label>
                            <label className='form-control'><strong>Serie:</strong> {selectedMachine.ref}</label>
                        </div>

                    )}

                </div>
                {idService && (
                    <div className='row'>
                        <div className='col-2'>
                            <h5>Nro Servicio:</h5>
                            <h3>{selectedService.idService}</h3>
                        </div>
                        <div className='col-2'>
                            <h5>Nro Factura:</h5>
                            <h3>{selectedService.billNumber}</h3>
                        </div>
                        <div className='col-3'>
                            <h5>Estado:</h5>
                            <select className="form-control" value={selectedService.status || ''} onChange={handleStatusChange} >
                                {Object.entries(statusList).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <h5>Archivo seleccionado:</h5>
                            <a target='_blank' href={`${process.env.REACT_APP_BACKEND_URL}/serviceFiles/${selectedService.fileName}`}>{selectedService.fileName}</a>

                        </div>
                    </div>
                )}
                <hr />

                {selectedMachine.idMachine && (
                    <div className=' mt-2 border rounded p-2'>
                        <h5>Detalles del Servicio</h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre del Servicio</th>
                                    <th>Archivo</th>
                                    <th>Recordatorio</th>
                                    <th>Fecha</th>
                                    <th>Mensaje Recordatorio</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {services.map((service, index) => (
                                    <tr key={index}>
                                        <td><input className='form-control' type="text" name="description" value={service.description} onChange={(e) => handleServiceChange(index, e)} /></td>
                                        <td>{!idService ? (<input className='form-control' type="file" name="file" onChange={(e) => handleServiceChange(index, e)} />) : (<a target='_blank' href={`${process.env.REACT_APP_BACKEND_URL}/serviceFilesDetail/${service.fileName}`}>{service.fileName}</a>)} </td>
                                        <td><input type="checkbox" name="reminder" value={service.reminder} onChange={(e) => handleServiceChange(index, e)} /></td>
                                        <td>{service.reminder && (<input className='form-control' type="date" name="reminderDate" value={service.reminderDate || new Date()} onChange={(e) => handleServiceChange(index, e)} />)} </td>
                                        <td>{service.reminder && (<input className='form-control' type="text" name="reminderMessage" value={service.reminderMessage || ''} onChange={(e) => handleServiceChange(index, e)} />)}</td>
                                        <td><button type="button" className="btn btn-danger" onClick={() => handleRemoveService(index)}>Eliminar</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="btn btn-primary" onClick={handleAddService}>Agregar Servicio</button>

                        <div className="d-flex flex-row-reverse">
                            <button type="button" className="btn btn-primary mt-2" onClick={handleSave}>Guardar</button>
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default ServicesAddPage;

import axiosInstance from '../util/axiosInstance'



export const getClients = async () => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/clients`);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};

export const getClientById = async (idClient) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/clients/` + idClient);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};


export const saveClient = async (client) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/clients`, client);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};


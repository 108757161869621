import React, { useContext, useState } from 'react';
import { AuthContext } from '../../util/authContext';

const Profile = () => {
    const { userRole } = useContext(AuthContext);
    const [user, setUser] = useState(userRole);

    const [isValid, setIsValid] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        // Validate the information here
        if (user.name &&  user.role) {
            setIsValid(true);
            // Save the information to the server or perform other actions
        } else {
            setIsValid(false);
        }
    };

    return (
        <div className="container col-6">
            <form>

                <div className="form-group">
                    {/* Add your circular picture component here */}
                    <div className="form-group">
                        <label>Name</label>
                        <input className="form-control" type="text" name="name" value={user.name} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label>Registration Date</label>
                        <p>{user.registrationDate}</p>
                    </div>
                    <div className="form-group">
                        <label>Role</label>
                        <select className="form-control" name="role" disabled value={user.role} onChange={handleInputChange}>
                            <option value="">Select a role</option>
                            <option value="1">Administrator</option>
                            <option value="2">User</option>
                        </select>
                    </div>
                    {isValid ? 'Information is valid' : 'Please provide valid information'}
                    <button className="btn btn-primary" onClick={handleSave}>Save</button>
                </div>
            </form>
        </div>
    );
};

export default Profile;
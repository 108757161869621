import axiosInstance from '../util/axiosInstance'

export const loginUser = async (user) => {

    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, user);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};
import React, { createContext, useState } from 'react';
import { authStore } from '../store/auth.store';
import { shallow } from 'zustand/shallow';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { isAuthenticated, role } = authStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    role: state.role
  }), shallow);
  const { setCurrentUser } = authStore();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [userRole, setUserRole] = useState('');
  // const [userEmail, setUserEmail] = useState('');
  // Define a function to handle login
  const login = (user) => {
    setCurrentUser(user);
  };

  // Define a function to handle logout
  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState } from 'react';
import { postMachine } from '../../services/machinesServices';

const MachineEditModal = ({ onClose, selectedMachine, fetchMachines }) => {
    const [machine, setMachine] = useState(selectedMachine);

    const handleSave = async () => {
        try {

            await postMachine(machine)
                .then((result) => {
                    fetchMachines();
                    onClose();
                })
                .catch((error) => {
                    console.log("Error creating user:", error);
                });
        } catch (error) {

        }
    };
    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setMachine({ ...machine, [name]: value });
    };
    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Machine</h5>
                        {/* <button type="button" className="close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="modal-body">
                        <div className='form'>
                            <div className='form-group'>
                                <label>Equipo:</label>
                                <input type="text" className="form-control" name='name' value={machine.name || ''} onChange={handleInputChange} />
                            </div>
                            <div className='form-group'>
                                <label>Marca:</label>
                                <input type="text" className="form-control" name='brand' value={machine.brand || ''} onChange={handleInputChange} />
                            </div>
                            <div className='form-group'>
                                <label>Modelo:</label>
                                <input type="text" className="form-control" name='model' value={machine.model || ''} onChange={handleInputChange} />
                            </div>
                            <div className='form-group'>
                                <label>Serie:</label>
                                <input type="text" className="form-control" name='ref' value={machine.ref || ''} onChange={handleInputChange} />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MachineEditModal;

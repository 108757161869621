import axiosInstance from '../util/axiosInstance'



export const getReminders = async () => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/servicesreminders`);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};

export const getServicesList = async (id, status, clients, machines) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/services`, {
            params: {
                "id": id,
                "status": status,
                "clients": clients,
                "machines": machines
            }
        });
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};

export const getServicesById = async (id) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/services/` + id);
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};


export const saveService = async (service) => {
    try {
        // const formData = new FormData();
        // formData.append(`idService`, service.idService);
        // formData.append(`active`, service.active);
        // formData.append(`mechanicName`, service.mechanicName);
        // formData.append(`status`, service.status);
        // formData.append(`billNumber`, service.billNumber);
        // formData.append(`file`, service.file);

        const response = await axiosInstance.put(`${process.env.REACT_APP_BACKEND_URL}/api/services`, service, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const { data } = response; // Extract the product data from the response
        return data;
    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};


export const saveWoleService = async (service) => {
    try {

        const formData = new FormData();
        // Append product data and files to the FormData object
        service.detail.forEach((det, index) => {
            formData.append(`services[${index}][idMachine]`, service.idMachine);
            formData.append(`services[${index}][idUser]`, service.idUser);
            formData.append(`services[${index}][description]`, det.description);
            formData.append(`services[${index}][reminder]`, det.reminder);
            formData.append(`services[${index}][reminderDate]`, det.reminderDate);
            formData.append(`services[${index}][reminderMessage]`, det.reminderMessage);
            formData.append(`services[${index}][file]`, det.file);
        });

        const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/services`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const { data } = response; // Extract the product data from the response
        return data;

    } catch (error) {
        console.log('Error fetching product:', error);
        throw error;
    }
};


import React, { useState, useEffect } from 'react';
import ClientEditModal from './clientEditModal'; // Import your custom modal component for editing clients
import MachineEditModal from './machineEditModal'; // Import your custom modal component for editing machines
import FeatureEditModal from './machineFeaturesEditModal'; // Import your custom modal component for editing features
import { getClientById, getClients } from '../../services/clientsServices';
import { getMachineFeatures } from '../../services/machinesServices';

const ClientsListPage = () => {
    const [clients, setClients] = useState([]);
    const [machines, setMachines] = useState([]);
    const [features, setFeatures] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');

    const fetchClients = async () => {
        try {
            const clientsData = await getClients();
            setClients(clientsData);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    const fetchMachines = async () => {
        if (selectedClient?.idClient) {
            try {
                const { machines } = await getClientById(selectedClient.idClient);
                setMachines(machines);
            } catch (error) {
                console.error('Error fetching machines:', error);
            }
        }
    };

    const fetchFeatures = async () => {
        if (selectedMachine?.idMachine) {
            try {
                const { features } = await getMachineFeatures(selectedMachine.idMachine);
                setFeatures(features);
            } catch (error) {
                console.error('Error fetching features:', error);
            }
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        fetchMachines();
    }, [selectedClient]);

    useEffect(() => {
        fetchFeatures();
    }, [selectedMachine]);

    const handleClientClick = async (client) => {
        setSelectedClient(client);
        setSelectedMachine(null);
        setSelectedFeature(null);
    };

    const handleMachineClick = async (machine) => {
        setSelectedMachine(machine);
        setSelectedFeature(null);
    };

    const handleFeatureClick = async (feature) => {
        setSelectedFeature(feature);
    };

    const handleEditClick = (type, param = null) => {

        if (type === 'client') {
            setSelectedClient(param);
        } else if (type === 'machine') {
            setSelectedMachine(param);
        } else if (type === 'feature') {
            setSelectedFeature(param);
        }
        setModalType(type);
        setEditModalOpen(true);
    };


    const handleCloseModal = () => {
        setEditModalOpen(false);
    };



    return (
        <div className='row'>
            <div className='col-4'>
                <div className='row'>
                    <h4 className='col-10'>Clientes</h4>
                    <button className='col-2 btn btn-primary' onClick={() => handleEditClick('client', {})}>Nuevo</button>
                </div>
                <br></br>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Nombre</th>
                                <th>Direccion</th>
                                <th>Tel</th>
                                <th>Activo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client) => (
                                <tr key={client.idClient} onClick={() => handleClientClick(client)} onDoubleClick={() => handleEditClick('client', client)}>
                                    <td>{client.name}</td>
                                    <td>{client.address}</td>
                                    <td>{client.phone}</td>
                                    <td>{client.active}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            {selectedClient && (
                <div className='col-4'>
                    <div className='row'>
                        <h4 className='col-10'>Maquinas</h4>
                        <button className='col-2 btn btn-primary' onClick={() => handleEditClick('machine', { idClient: selectedClient.idClient })}>Nuevo</button>
                    </div>
                    <br></br>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Equipo</th>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Serie</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machines.map((machine) => (
                                    <tr key={machine.idMachine} onClick={() => handleMachineClick(machine)} onDoubleClick={() => handleEditClick('machine', machine)}>
                                        <td>{machine.name}</td>
                                        <td>{machine.brand}</td>
                                        <td>{machine.model}</td>
                                        <td>{machine.ref}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            )}

            {selectedMachine && (


                <div className='col-4'>
                    <div className='row'>
                        <h4 className='col-10'>Caracteristicas</h4>
                        <button className='col-2 btn btn-primary' onClick={() => handleEditClick('feature', { idMachine: selectedMachine.idMachine })}>Nuevo</button>
                    </div>
                    <br></br>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Caracteristica</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {features.map((feature) => (
                                    <tr key={feature.idFeature} onClick={() => handleFeatureClick(feature)} onDoubleClick={() => handleEditClick('feature', feature)}>
                                        <td>{feature.name}</td>
                                        <td>{feature.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            )}
            {editModalOpen && modalType === 'client' && <ClientEditModal onClose={handleCloseModal} selectedClient={selectedClient} fetchClients={fetchClients} />}
            {editModalOpen && modalType === 'machine' && <MachineEditModal onClose={handleCloseModal} selectedMachine={selectedMachine} fetchMachines={fetchMachines} />}
            {editModalOpen && modalType === 'feature' && <FeatureEditModal onClose={handleCloseModal} selectedFeature={selectedFeature} fetchFeatures={fetchFeatures} />}
        </div>
    );
};

export default ClientsListPage;

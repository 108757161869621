import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ServiceDetailsModal = ({ service, onClose }) => {
    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles del Servicio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {service && (
                    <div>
                        <p><strong>Nombre:</strong> {service.name}</p>
                        <p><strong>Precio:</strong> {service.price}</p>
                        {/* Add more details as needed */}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={onClose}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ServiceDetailsModal;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from '../components/layout';
import { AuthContext } from './authContext';
import HomePage from '../pages/homePage';
import DashboardAdminPage from '../pages/dashboardAdminPage';
import Profile from '../pages/user/profilePage';
import { authStore } from '../store/auth.store';
import { shallow } from 'zustand/shallow';
import UsersAdminPage from '../pages/user/usersListPage';
import ServicesAddPage from '../pages/services/serviceAddPage';
import ServicesListPage from '../pages/services/serviceListPage';
import ClientsListPage from '../pages/clients/clientsListPage';

const AppRouter = () => {
  const { isAuthenticated, role } = authStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    role: state.role
  }), shallow);

  return (
    <Router >
      <Layout>
        <Routes>
          {/* Public routes */}
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardAdminPage />} />
              <Route path="/users" element={<UsersAdminPage />} />
              <Route path="/services" element={<ServicesListPage />} />
              <Route path="/services/add" element={<ServicesAddPage />} />
              <Route path="/services/:idService" element={<ServicesAddPage />} />
              <Route path="/clients" element={<ClientsListPage />} />
              {/* <Route path="/shipping/:idShipping" element={<ShippingPage />} />
              <Route path="/shippingFinish/:idShipping" element={<ShippingFinishPage />} />
              <Route path="/shippingViewer/:idShipping" element={<ShippingViewerPage />} />
              <Route path="/shippingHistory" element={<ShippingHistoryPage />} />
              <Route path="/packing2Finish/:idPacking" element={<Packing2FinishPage />} />
              <Route path="/packingHistory" element={<PackingHistoryPage />} />
              <Route path="/productslist" element={<ProductListPage />} />
              <Route path="/billing" element={<BillingPage />} />
              <Route path="/billingViewer/:idBill" element={<BillingViewerPage />} />
              <Route path="/billing/new" element={<BillingNewPage />} />
              <Route path="/createProduct/:idProduct" element={<CreateProductPage />} />
              <Route path="/users" element={<UsersListPage />} /> */}

              <Route path="/profile" element={<Profile />} />

              {/* Redirect to homepage for unknown routes 
              <Route path="*" element={<Navigate to="/dashboard" replace />} />*/}
            </>

          ) : (
            <Route path="/" element={<HomePage />} />
          )}

          {/* Redirect to homepage for unknown routes */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
import axiosInstance from '../util/axiosInstance'

export async function getUserInformation(email) {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/${email}`);
    const { data } = response; // Extract the user role from the response
    return data;
  } catch (error) {
    // console.log('Error retrieving user role:', error); // Handle any errors that occurred during the API request
    throw error;
  }
};

export async function getUsersList() {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`);
    const { data } = response; // Extract the user role from the response
    return data;
  } catch (error) {
    // console.log('Error retrieving user role:', error); // Handle any errors that occurred during the API request
    throw error;
  }
};

export async function saveUser(user) {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/user`, user);
    const { data } = response; // Extract the user role from the response
    return data;
  } catch (error) {
    // console.log('Error retrieving user role:', error); // Handle any errors that occurred during the API request
    throw error;
  }
};


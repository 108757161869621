import React, { useEffect, useState } from 'react';
import { getServicesList } from '../../services/serviceServices'; // Adjust your imports as needed
import ServiceDetailsModal from './ServiceDetailsModal'; // Import your modal component for showing service details
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getClients } from '../../services/clientsServices';
import * as XLSX from 'xlsx'; // Import the xlsx library
import { getMachinesByClientIds } from '../../services/machinesServices';

const ServicesListPage = () => {
    const [services, setServices] = useState([]);
    const [clients, setClients] = useState([]);
    const [machines, setMachines] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const clients = await getClients();
                setClients(clients);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []);

    // useEffect(() => {
    //     filterServices();
    // }, [selectedClients, selectedMachines, services]);

    useEffect(() => {
        const fetchMachines = async () => {
            if (selectedClients.length === 0) {
                setMachines([]);
                return;
            }
            try {
                const machines = await getMachinesByClientIds(selectedClients);
                setMachines(machines);
            } catch (error) {
                console.error('Error fetching machines:', error);
            }
        };

        fetchMachines();
    }, [selectedClients]);

    const handleShowDetails = (service) => {
        setSelectedService(service);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedService(null);
        setShowModal(false);
    };

    const handleAddService = () => {
        // Redirect to the form for adding a new service
        // You can implement the logic for navigation based on your routing system
    };

    const handleClientChange = (event) => {
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedClients(value);
    };

    const handleMachineChange = (event) => {
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedMachines(value);
    };

    const filterServices = () => {
        const filtered = services.filter(service => {
            const clientMatch = selectedClients.length === 0 || selectedClients.includes(service.clientId);
            const machineMatch = selectedMachines.length === 0 || selectedMachines.includes(service.machineId);
            return clientMatch && machineMatch;
        });
        setFilteredServices(filtered);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredServices.map(service => ({
            Fecha: moment(service.createDate).format("DD/MM/yyyy HH:mm"),
            Factura: service.billNumber,
            Mecanico: service.mechanicName,
            Cliente: service.clientName,
            Maquina: service.machineName,
            Usuario: service.userName,
            Estado: service.status,
            Actualizacion: moment(service.updateDate).format("DD/MM/yyyy HH:mm"),
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Servicios");
        XLSX.writeFile(workbook, "Servicios.xlsx");
    };

    const loadServices = async () => {
        try {

            const services = await getServicesList(null, null, selectedClients, selectedMachines);
            setFilteredServices(services);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    return (
        <div>
            <h5>Lista de Servicios</h5>
            <div className='row'>
                <div className='col'>
                    <Link to="/services/add" className="btn btn-primary">Agregar Servicio</Link>
                </div>

                <div className="mb-3 col">
                    <label>Filtrar por Clientes:</label>
                    <select multiple className="form-control" onChange={handleClientChange}>
                        {clients.map(client => (
                            <option key={client.idClient} value={client.idClient}>{client.name}</option>
                        ))}
                    </select>
                </div>

                <div className="mb-3 col">
                    <label>Filtrar por Máquinas:</label>
                    <select multiple className="form-control" onChange={handleMachineChange}>
                        {machines.map(machine => (
                            <option key={machine.idMachine} value={machine.idMachine}>{machine.name}</option>
                        ))}
                    </select>
                </div>
                <div className='col'>
                    <button onClick={loadServices} className="btn btn-primary">Consultar</button>
                </div>
                <div className='col'>
                    <button onClick={exportToExcel} className="btn btn-success">Exportar a Excel</button>
                </div>

            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Maquina</th>
                        <th>Usuario</th>
                        <th>Detalles</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredServices.map((service, index) => (
                        <tr key={index}>
                            <td>{moment(service.createDate).format("DD/MM/yyyy HH:mm")}</td>
                            <td>{service.clientName}</td>
                            <td>{service.machineName}</td>
                            <td>{service.userName}</td>
                            <td>
                                <a className="btn btn-primary" href={'services/' + service.idService} target='_blank'>Ver Detalles</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showModal && <ServiceDetailsModal service={selectedService} onClose={handleCloseModal} />}
        </div>
    );
};

export default ServicesListPage;

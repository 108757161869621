import React, { useContext, useState } from 'react';
import { loginUser } from '../services/authenticationService';
import { Navigate, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { getUserInformation } from '../services/userService';
import { AuthContext } from '../util/authContext';

const HomePage = () => {
  const { login, logout } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      await loginUser(formData)
        .then((response) => {


          const { token } = response;
          localStorage.setItem('token', token);

          let decode = jwt_decode(token);
          login(decode);

          console.log(decode);
          navigate('/dashboard');
          // getUserInformation(decode.user)
          //   .then((role) => {


          //   })
          //   .catch((error) => {
          //     console.log('Error retrieving user role:', error);
          //   });
        })
        .catch((error) => {
          console.log('Error updating product:', error);
        });
    } catch (error) {
      console.error('Login failed:', error.response.data.message);
    }
  };

  return (
    <div>
      <div>
        <h1>Bienvenid@, por favor inicie sesion.</h1>

      </div>
      <div className='col-md-6'>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="usernameInput">Usuario</label>
            <input
              type="text"
              className="form-control"
              id="usernameInput"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="passwordInput">Clave</label>
            <input
              type="password"
              className="form-control"
              id="passwordInput"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Entrar</button>
        </form>
      </div>

    </div>
  );
};

export default HomePage;

import { create } from "zustand";
import { persist } from "zustand/middleware";

export const authStore = create()(
    persist(
        (set) => ({
            isAuthenticated: false,
            role: {},
            setCurrentUser: (value) => set(state => (
                {
                    isAuthenticated: value !== null,
                    role: value
                })),
            clearStore: () => {
                set({}, true);
            },
        }),
        {
            name: "global",
            getStorage: () => localStorage,
        }
    )
);
import React, { useState } from 'react';
import { saveClient } from '../../services/clientsServices';

const ClientEditModal = ({ selectedClient, onClose, fetchClients }) => {
    const [client, setClient] = useState(selectedClient);

    const handleSave = async () => {
        try {
            await saveClient(client)
                .then((result) => {
                    fetchClients();
                    onClose();
                })
                .catch((error) => {
                    console.log("Error creating user:", error);
                });
        } catch (error) {

        }

    };


    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setClient({ ...client, [name]: value });
    };
    return (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Editar Cliente</h5>
                        {/* <button type="button" className="close" onClick={onClose} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="modal-body">
                        <div className='form'>
                            <div className='form-group'>
                                <label>Nombre:</label>
                                <input type="text" className='form-control' name='name' value={client.name || ''} onChange={handleInputChange} />
                            </div>
                            <div className='form-group'>
                                <label>Direccion:</label>
                                <input type="text" className='form-control' name='address' value={client.address || ''} onChange={handleInputChange} />
                            </div>
                            <div className='form-group'>
                                <label>Numero Telefono:</label>
                                <input type="text" className='form-control' name='phone' value={client.phone || ''} onChange={handleInputChange} />
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={handleSave}>Guardar</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientEditModal;
import axios from "axios";


// import TokenService from "services/token.service";
console.log("DIRECCION BACKEND: ", process.env.REACT_APP_API_URL)
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Before making request, do the following
instance.interceptors.request.use(
  (config) => {
    // console.log("getLocalAccessToken", TokenService.getLocalAccessToken());
    // const token = TokenService.getLocalAccessToken();
    const token = localStorage.getItem('token');
    document.getElementById("profile_modal").style.display = "block";
    if (token) {
      config.headers["authorization"] = token;
    }
    return config;
  },
  (error) => {

    return Promise.reject(error);
  }
);

// With response data, do the following
instance.interceptors.response.use(
  (res) => {
    document.getElementById("profile_modal").style.display = "none";

    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      document.getElementById("profile_modal").style.display = "none";

      if (err.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('global');
        // location.reload()
      }
      // access token expired
      if (err.response.status === 403 && !originalConfig._retry) {
        // handle infinite loop
        originalConfig._retry = true;

        // console.log("refresh", TokenService.getLocalRefreshToken());
        try {
          const rs = await instance.post("/auth/token", {
            refreshToken: localStorage.getItem('token'),
          });

          // console.log("response", rs);

          const { accessToken } = rs.data;

          // console.log("updateNewAccessToken", accessToken);
          // TokenService.updateNewAccessToken(accessToken);
          localStorage.setItem("token", JSON.stringify(accessToken));

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      // refresh token expired
    }

    return Promise.reject(err);
  }
);

export default instance;
